import React from 'react'
import { Trans, Link, useTranslation } from 'gatsby-plugin-react-i18next'

const Steps = () => {
  const { t } = useTranslation()
  const steps = t('page_home_section_2_descr_list', {
    returnObjects: true,
  })
  return (
    <section className="container-blue">
      <div className="steps bg-primary">
        <div className="steps__content">
          <h2 className="steps__title">{t('page_home_section_2_title')}</h2>

          <ul className="steps__list">
            {steps.map((step, i) => (
              <li>
                <span className="steps__list_number font-bold">{i + 1} - </span>
                <span className="h4-style font-semibold white">{step}</span>
              </li>
            ))}
          </ul>
          <div className="steps__btn">
            <Link to="/models" className=" btn btn-primary btn-white secondary">
              {t('page_home_section_2_btn')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Steps
